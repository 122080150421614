export const firstCollection1 = "Members";
export const firstCollection2 = "Coupons";
export const firstCollection3 = "Events";
export const firstCollection4 = "Notifications"
export const firstCollection5 = "Conversations";
export const firstCollection6 = "Messages";
export const firstCollection7 = "StampCards";
export const secondCollection1 = "point";
export const secondCollection2 = "coupon";
export const secondCollection3 = "event";
export const secondCollection4 = "notification";
export const secondCollection5 = "stamp";
export const secondCollection6 = "card";
// export const secondCollection4 = "chat";