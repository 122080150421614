import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router'
import TabsPage from '../views/TabsPage.vue'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "@/composables/helper/useFirebase"
const auth = getAuth(app);

let isAuthenticated = false;

onAuthStateChanged(auth, (user) => {
    isAuthenticated = !!user;
});

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/top'
  },
  {
    path: '/',
    component: TabsPage,
    children: [
      {
        path: '/',
        redirect: '/top'
      },
      {
        path: 'top',
        name: 'Top',
        component: () => import('@/views/TopPage.vue')
      },
      {
        path: 'point',
        component: () => import('@/views/PointPage.vue')
      },
      {
        path: 'coupon',
        component: () => import('@/views/CouponPage.vue')
      },
      {
        path: 'notification',
        component: () => import('@/views/NotificationPage.vue')
      },
      {
        path: 'event',
        component: () => import('@/views/EventPage.vue')
      },
      {
        path: 'chat',
        component: () => import('@/views/ClientChatPage.vue')
      },
      {
        path: 'stamp',
        component: () => import('@/views/StampPage.vue')
      },
      
    ]
  },
  {
    path: '/createUser',
    name: 'createUser',
    component: () => import('@/views/CreateUserPage.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/SettingsPage.vue')
  },
  {
    path: '/login',
    component: () => import('@/views/LoginPage.vue')
  },
  {
    path: '/CouponDetailPage',
    name: 'CouponDetailPage',
    component: () => import('@/views/CouponDetailPage.vue'),
    props: true
  },
  {
    path: '/StampDetailPage',
    name: 'StampDetailPage',
    component: () => import('@/views/StampDetailPage.vue'),
    props: true
  },
  {
    path: '/setStampPage',
    name: 'setStampPage',
    component: () => import('@/views/setStampPage.vue'),
    props: true
  },
  {
    path: '/setStampQrPage',
    name: 'setStampQrPage',
    component: () => import('@/views/setStampQrPage.vue'),
    props: true
  },
  {
    path: '/NotificationDetailPage/:id',
    name: 'NotificationDetailPage',
    component: () => import('@/views/NotificationDetailPage.vue'),
    props: true
  },
  {
    path: '/adminSettings',
    component: () => import('@/views/adminSettings.vue'),
  },
  {
    path: '/adminSettings/memberEdit',
    component: () => import('@/views/memberEdit.vue'),
  },
  {
    path: '/adminSettings/memberEdit/issueMember',
    component: () => import('@/views/issueMember.vue'),
  },
  {
    path: '/adminSettings/memberEdit/memberEditDetail',
    component: () => import('@/views/memberEditDetail.vue'),
  },
  {
    path: '/adminSettings/couponEdit',
    component: () => import('@/views/couponEdit.vue'),
  },
  {
    path: '/adminSettings/couponEdit/couponEditDetail',
    name: 'couponEditDetail',
    component: () => import('@/views/couponEditDetail.vue'),
  },
  {
    path: '/adminSettings/couponEdit/issueCoupon',
    name: 'issueCoupon',
    component: () => import('@/views/issueCoupon.vue'),
  },
  {
    path: '/adminSettings/pushNotification',
    component: () => import('@/views/pushNotification.vue'),
  },
  {
    path: '/adminSettings/adminChat',
    component: () => import('@/views/adminChat.vue'),
  },
  {
    path: '/adminSettings/stampEdit',
    name: 'stampEdit',
    component: () => import('@/views/stampEdit.vue')
  },
  {
    path: '/adminSettings/stampEdit/stampEditDetail',
    name: 'stampEditDetail',
    component: () => import('@/views/stampEditDetail.vue')
  },
  {
    path: '/adminSettings/stampEdit/issueStamp',
    name: 'issueStamp',
    component: () => import('@/views/issueStamp.vue')
  },
  {
    path:'/memberEditDetail',
    component: () => import('@/views/memberEditDetail.vue')
  }
  

]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.isReady().then(() => {
  console.log('router.currentRoute.value.name',router.currentRoute.value.name)
  if (router.currentRoute.value.name === 'Settings') {
    router.push({ name: 'Top' });
  }
})

export default router

router.beforeEach((to, from, next) => {
  onAuthStateChanged(auth, (currentUser) => {
    if (!currentUser) {
      console.log('サインインしていない')
      if(to.fullPath !== '/login' && to.fullPath !== '/createUser' && to.fullPath !== '/setStampQrPage') {
      sessionStorage.setItem('redirectPath', to.fullPath);
      }
      if (to.path !== '/login' && to.path !== '/createUser') { 
        // '/login'または'/createUser'へのリダイレクトの場合はそのまま処理を進める
        console.log('リダイレクト')
        next('/login');
      } else {
        console.log('リダイレクトしない')
        next();
      }
    } else {
      console.log('サインイン済み')
      next();
    }
  });
});
