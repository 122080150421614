import { getFirestoreData, addFirestoreData, deleteFirestoreData, updateFirestoreData, getDocId,getDocData  } from './helper/useFirestore';
import { Terms,Coupon,MemberCoupon } from './helper/useEnum';
import { firstCollection1,firstCollection2,secondCollection2 } from './helper/useFirestoreCollectionNames'
import { getDateString,getJapaneseDateString } from './helper/useDateProcessing'


//発行済みクーポンの一覧表示
export const getAllCoupons = async (terms?:Terms) => {
    const couponPath = firstCollection2;
    const coupons = await getFirestoreData(couponPath);
    //couponsのuseLimitDateをgetDateStringを使って日付の形式を変更する
    const exCounpons = coupons?.map((coupon:Coupon) => {
        const couponDate = getDateString(coupon.useLimitDate);
        coupon.useLimitDate = getJapaneseDateString(couponDate);
        return coupon;
    })

    return exCounpons;
}

//クーポンの発行
export const issueCoupon = async (data: Coupon):Promise<string> => {
    const couponPath = firstCollection2;
    const docId = await addFirestoreData(couponPath, data);
    return docId;
}

//発行したクーポンを会員全員に付与する
export const giveCoupon = async (couponId:string, memberId?:string) => {
    //会員全員のuidを取得する
    const memberPath = firstCollection1;
    const members = await getFirestoreData(memberPath);
    const memberIds = members?.map((member:any) => {
        return member.id;
    })
    console.log("memberIds",memberIds);
    //会員全員にクーポンを付与する
    memberIds?.forEach(async (memberId:string) => {
        const setMemberPath = `${firstCollection1}/${memberId}/${secondCollection2}/${couponId}`;
        console.log("setMemberPath",setMemberPath)
        const coupon:MemberCoupon ={
            use:false,
        } 
        await addFirestoreData(setMemberPath, coupon);
    })
    return true;
}

//クーポンの削除
export const deleteCoupon = async (couponId: string) => {
    const couponPath = firstCollection2;
    await deleteFirestoreData(couponPath, couponId);
    return true;
}

//指定したクーポン情報を取得する
export const getCouponInfomation = async (couponId:string) => {
    const couponPath = `${firstCollection2}/${couponId}`;
    const coupon = await getDocData(couponPath);
    return coupon;
}

//指定したクーポンを編集する
export const updateCoupon = async (couponId:string, data: Coupon) => {
    const couponPath = firstCollection2;
    await updateFirestoreData(couponPath, couponId, data);
    return true;
}

//過去に作ったクーポンを複製
export const dupiliateCoupon = async (couponId:string) => {
    const couponPath = `${firstCollection2}/${couponId}`;
    const originCoupon = await getCouponInfomation(couponPath);
    const newCoupon:Coupon = {
        name: originCoupon?.name,
        description: originCoupon?.description,
        discount: originCoupon?.discount,
        useLimitDate: originCoupon?.useLimitDate,
        noDuplicate: false,
    };
    const result = issueCoupon(newCoupon);
    return result;
}