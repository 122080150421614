//cDateのライブラリを使う
import {cdate} from "cdate";

//今日の日付を取得する
export const getToday = () => {
    const today = new Date();
    const todayString = cdate(today).format('YYYY-MM-DD');
    return todayString;
}

//Firestoreで日付で取得するコレクションを指定するために、Firestore用の日付形式に変更する
export const getFirestoreDate = (date: string) => {
    const dateArray = date.split('-');
    const firestoreDate = new Date(Number(dateArray[0]), Number(dateArray[1]) - 1, Number(dateArray[2]));
    return firestoreDate;
}

//firestoreで取得したTimestamp型を、2023年12月31日のような形式に変更する
export const getDateString = (timestamp: any) => {
    const date:Date = timestamp.toDate();
    const dateString = cdate(date).format('YYYY-MM-DD');
    return dateString;
}

//firestoreへ保存するために、日付をdate型にする
export const getDate = (date: string) => {
    const dateArray = date.split('-');
    const dateObject = new Date(Number(dateArray[0]), Number(dateArray[1]) - 1, Number(dateArray[2]));
    return dateObject;
}

//YYYY-MM-DDをYYYY年MM月DD日に変換する
export const getJapaneseDateString = (date: string) => {
    const dateArray = date.split('-');
    const japaneseDateString = `${dateArray[0]}年${dateArray[1]}月${dateArray[2]}日`;
    return japaneseDateString;
}

//今日の日付と指定された日付の比較を行う
//指定された日付が過去ならfalse、未来ならtrueを返す
export const dateCompraison = (date: string) => {
    const today = new Date();
    date = getDateString(date);
    const todayString = cdate(today).format('YYYY-MM-DD');
    console.log('dateCompraison', date, todayString);
    if (date >= todayString) {
        return true;
    } else {
        return false;
    }
}

// プッシュ通知用の日付。FCMはstring型しか受け入れないため
export const exchangeTimestamp = () :string =>{
    // cdateで取得した時間をstring型にする
    const timestamp = cdate().format('YYYY-MM-DD HH:mm:ss');
    return timestamp;
};

// プッシュ通知用の日付。受け取ったstring型の日付をDate型に変換する
export const exchangeTimestampToDate = (timestamp:string) :Date =>{
    const date = new Date(timestamp);
    return date;
}