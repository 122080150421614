//useFirestore.tsから関数呼び出す
import { getFirestoreData, addFirestoreData, deleteFirestoreData, updateFirestoreData, getDocId,getDocData,updateFirestoreDataTransaction,addFirestoreDataTransaction,deleteFirestoreDataField,getFirestoreDataListener,getTimestamp } from './helper/useFirestore';
import { getToday,dateCompraison,exchangeTimestamp } from './helper/useDateProcessing';
import { getCouponInfomation } from './useCoupons';
import { Member,IssueTransaction,pushMessages,ChatMessages,Conversations,Coupon,pushMessageArray,UserData} from './helper/useEnum';
import { firstCollection1,firstCollection2,firstCollection4,firstCollection5,firstCollection6,secondCollection1,secondCollection2,secondCollection4 } from './helper/useFirestoreCollectionNames'
import { getAuth,onAuthStateChanged,setPersistence,browserLocalPersistence,signInWithEmailAndPassword } from 'firebase/auth'
import { requestPermission,refreshPushToken,deletePushToken,permissionCheck } from './helper/usePushNotification'
import { adminUids } from './helper/useAdminUids'
import {subTokenToTopic,addTokenToTopic,createUser} from './helper/useCloudFunctions'
export const auth = getAuth();
setPersistence(auth, browserLocalPersistence);
console.log('setPersistence',browserLocalPersistence)
let user = auth.currentUser;

onAuthStateChanged(auth, (currentUser) => {
    user = currentUser;
    if (user) {
        // User is signed in.
        console.log('Signed in as:', user.uid);
    } else {
        // No user is signed in.
        console.log('No user is signed in.');
    }
});

// ログインする
export const logIn = async (email:string,password:string) => {
    await setPersistence(auth, browserLocalPersistence);
    return await signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
        // Signed in
        console.log('userCredential',userCredential)
        user = userCredential.user;
        return user;
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('errorCode',errorCode)
        console.log('errorMessage',errorMessage)
        return error;
    });
}


//管理者でログインしているかどうかを判定する
export const isAdmin = async () => {
    const result = await user?.getIdTokenResult()
    if(result?.claims.admin){
        return true;
    }else{
        return false;
    }
}

// ログアウトする
export const logOut = async () => {
    await auth.signOut();
    return true;
}

// 新規ユーザー登録をする
export const signUp = async (userData:UserData) => {
    const result = await createUser(userData);
    return result;
}

//Firestoreから全てのメンバーを取得する

export const getAllMembers = async () => {

    const members = await getFirestoreData(firstCollection1);
    console.log('members',members)
    return members;
}

//Firestoreからメンバー一人の情報を取得する
//クライアント側で取得する際は、自動でログインしているuidで取得
//管理側で取得する際は、取得したいuidを引数に指定する
export const getMembers = async (uid?:string) => {
    let userId = user?.uid;
    if(uid){
        userId = uid;
    }
    console.log('getMembers',userId)
    const members = await getFirestoreData(`${firstCollection1}/${userId}`);
    return members;
}

//FirestoreにMembersの情報を追加する関数
//引数にパスを指定する
//条件があれば、第２引数に指定する

export const issueMemberFrontFunction = async (newMember?:Member) => {
    console.log('issueMemberFrontFunction',newMember)
    if(newMember){
        const issueTransaction:IssueTransaction = [
            {
                path: firstCollection1,
                id: newMember.uid,
                data: {
                    name: newMember.name,
                    email: newMember.email,
                }
            },
            {
                path: `${firstCollection1}/${newMember.uid}/${secondCollection1}`,
                id: newMember.uid,
                data: {
                    point:newMember.point || 0,
                }
            },
        ]
    console.log('issueTransaction',issueTransaction)
    const result = await addFirestoreDataTransaction(...issueTransaction);
    return result
    } else {
        const issueTransaction:IssueTransaction = [
            {
                path: firstCollection1,
                id: user?.uid,
                data: {
                    name: "名無しさん",
                    email: "noname@noname.com",
                }
            },
            {
                path: `${firstCollection1}/${user?.uid}/${secondCollection1}`,
                id: user?.uid,
                data: {
                    point:0,
                }
            },
        ]
        console.log('issueTransaction',issueTransaction)
        const result = await addFirestoreDataTransaction(...issueTransaction);
        return result
    }
}

//FirestoreのMembersの情報を削除する関数
//引数にパスを指定する
//条件があれば、第２引数に指定する

export const deleteMember = async (data:string) => {
    const memberPath = firstCollection1
    const memberId = user?.uid
    if(memberId != undefined){
    await deleteFirestoreDataField(memberPath, memberId, data);
    }
}

//FirestoreのMembersの情報を更新する関数
//引数にパスを指定する
//条件があれば、第２引数に指定する

export const updateMember = async (memberId: string, data: Member) => {
    const memberPath = firstCollection1
    const result = await updateFirestoreData(memberPath, memberId, data);
    return result;
}

//所有しているクーポンのIDを取得する
export const getMyCouponsId = async (couponId?:string) => {
    //  const uid = await user?.uid;
    //  console.log('getMyCouponsId uid',uid)
    let memberPath = `${firstCollection1}/${user?.uid}/${secondCollection2}`;
    if(couponId){
        memberPath += `/${couponId}`;
    }
    const myCouponsId = await getFirestoreData(memberPath);
    return myCouponsId;
}


//所有しているクーポンを使用する
export const useCoupon = async ( couponId: string) => {
    console.log('useCoupon')
    const couponPath = firstCollection2;
    const coupon = await getCouponInfomation(couponId);
    console.log('useCoupon coupon' ,coupon )

    //クーポンの使用期限をチェックする
    const couponDate = dateCompraison(coupon?.useLimitDate);
    console.log('useCoupon couponDate' ,couponDate )
    //クーポンの使用期限が過去なら、クーポンを使用できない
    if (couponDate === false) {
        return false;
    }else if(couponDate === true) {
        //クーポン使用
        const memberPath = `${firstCollection1}/${user?.uid}/${secondCollection2}`;
        const result = await updateFirestoreDataTransaction(memberPath, couponId, { use: true },couponPath, couponId, { noDuplicate: true });
        if(result){
            return true;
        }else{
            return false;
        }
    }

}

//所有しているポイントを取得する

export const getPoint= async () => {
    console.log('getPoint')
    const pointPath = `${firstCollection1}/${user?.uid}/${secondCollection1}`;
    console.log('pointPath', pointPath)
    const allPoints = await getFirestoreData(pointPath);
    //allPointsのデータをreduceで全て合計する
    //合計するのはpointのみで、それ以外のデータやデータがない場合は0を返す
    const point = allPoints?.reduce((acc: any, cur: any) => {
        if (cur.point) {
            return acc + cur.point;
        } else {
            return acc + 0;
        }
    }, 0);
    
    return point;
}

//ポイントを加算する

export const addPoint = async (addPoint: number, uid?:string) => {
    if(addPoint < 0) {return false;}
    let userId = user?.uid;
    if(uid){
        userId = uid;
    }
    const pointPath = `${firstCollection1}/${userId}/${secondCollection1}`;
    await addFirestoreData(pointPath, {"point":addPoint});
    return true;
}

//ポイントを使用する（減算する）
export const subPoint = async (subPoint: number, uid?:string) => {
    if(subPoint > 0) {return false;}
    let userId = user?.uid;
    if(uid){
        userId = uid;
    }
    const nowPoint = await getPoint();
    const pointCheck = nowPoint - subPoint;
    if(pointCheck < 0) {return false;}
    const pointPath = `${firstCollection1}/${userId}/${secondCollection1}`;
    await addFirestoreData(pointPath, {"point":subPoint});
    return true;
}

//参加イベントを取得する
export const getMyEvent = async (path: string) => {
    const event = await getFirestoreData(path);
    return event;
}

//プッシュ通知の許可を取り、新規にプッシュ通知用のトークンを取得する
export const getPushToken = async (): Promise<string | boolean> => {
    // await deletePushToken();
    const token = await requestPermission();
    const path = `${firstCollection1}/${user?.uid}`;
    const result = await addFirestoreData(path, { token });
    return token;
}

//トークンを再生成する
export const reGetPushToken = async () => {
    await deletePushToken();
    const token = await refreshPushToken();
    return token 
}

// チャットの通知の許可を書き込む
export const setChatPermission = async (chatPermission:boolean) => {
    if (user == null) {
        throw new Error("User is not signed in.");
    }
    console.log('setChatPermission',chatPermission)
    const path = firstCollection1;
    const options = {
        chatPermission
    }
    const result = await updateFirestoreData(path,user?.uid, options);
    if(!result){//初回時
        const result = await addFirestoreData(`${path}/${user.uid}`, options);
        return result;
    }
    return result;
}

// お知らせの通知の許可を書き込む

export const setInfomationPermission = async (infomationPermission:boolean) => {
    if (user == null) {
        throw new Error("User is not signed in.");
    }
    const path = firstCollection1;
    const options = {
        infomationPermission
    }
    try{
        const result = await updateFirestoreData(path,user?.uid, options);
        return result;
    }catch(e){
        console.log('setInfomationPermission error',e)
        const result = await addFirestoreData(`${path}/${user.uid}`, options);
        return result;
    }
}

//お知らせのトピックを登録・解除を行う
export const setInfomationTopic = async (infomationTopic:boolean,token:string) => {
    if (user == null) {
        throw new Error("User is not signed in.");
    }
    if(infomationTopic){
        await addTokenToTopic('infomation',token);
        return 'infomationトピックを登録しました'
    } else {
        await subTokenToTopic('infomation',token);
        return 'infomationトピックを解除しました'
    }
}
    

//プッシュ通知の許可を停止する
export const stopPushPermission = async () => {
    const path = `${firstCollection1}/${user?.uid}`;
    const options = {
        "pushPermission":false,
        "pushToken":"",
    }
    const result = await addFirestoreData(path, options);
    return result
}


//プッシュ通知の許可を取得する
export const getPushPermission = async () => {
    const path = `${firstCollection1}/${user?.uid}`;
    const result = await getFirestoreData(path);
    return result;
}

// プッシュ通知を受け取る。参照先はNotification
export const getPushMessage = async () => {
    console.log('getPushMessage')
    const path = firstCollection4;
    const terms = {
        topic:'infomation',
        orderByKey:'createdAt',
    }
    const result = await getFirestoreData(path,terms);
    console.log('getPushMessage result',result)
    if (!result) {
        throw new Error("Failed to get data from Firestore"); // エラーをスローする
    }
    return result;
}

// プッシュ通知のreadをtrueにする
export const readPushMessage = async (id:string) => {
    const path = `${firstCollection1}/${user?.uid}/${secondCollection4}/${id}`;
    const result = await addFirestoreData(path, { read: true });
    return result;
}

// 
export const checkUnreadNotification = async () => {
    const path = `${firstCollection1}/${user?.uid}/${secondCollection4}`;
    const result = await getFirestoreData(path);
    return result;
}

//プッシュ通知の本文をfirestoreへ書き込む (プッシュ通知用)

export const setPushMessage = async (message:pushMessages) => {
    const path = firstCollection4;
    message.createdAt = exchangeTimestamp();
    const result = await addFirestoreData(path, message);
    return result;
}

// フォアグラウンドで受け取った通知を取得する
export const getForegroundMessage = async (id:string) => {
    const path = `${firstCollection4}/${id}`;
    const result = await getFirestoreData(path);
    if(!result){return false;}
    result.id = id;
    return result;
}

//プッシュ通知の本文をfirestoreへ書き込む (チャット用)
export const setPushChatMessage = async (message:ChatMessages) => {
    console.log('setPushChatMessage')
    const path = firstCollection6;
    const useAdminID = adminUids.find((adminId) => adminId.uid !== message.senderId);
    console.log('useAdminID', useAdminID)
    if(useAdminID){
        message.click_action = "./adminSettings/adminChat";
    } else {
        message.click_action = "./chat";
    }
    const result = await addFirestoreData(path, message);
    return result;
}
// Conversationsコレクションに、自分のuidを追加する。追加済みの場合は何もしない
export const addConversation = async () => {
    const path = firstCollection5;
    const terms = {
        memberId:user?.uid,
        adminId:adminUids[0].uid,
        adminId2:adminUids[1].uid,
        clientName:user?.displayName,
    }
    const result = await addFirestoreData(path, terms);
    return result;
}
// uidを取得する
export const getMyUid = () => {
    const uid = user?.uid;
    console.log('getMyUid',uid)
    return uid;
}
// conversationsから自分以外のuidを取得する
export const getRecievedUid = async () => {
    const path = firstCollection5;
    const terms = {
        memberId:user?.uid,
    }
    const result = await getFirestoreData(path, terms);
    const getRecievedUid = result?.filter((item:Conversations) => item.memberId !== user?.uid)?.memberId;
    console.log('getRecievedUid',getRecievedUid)
    return getRecievedUid;
}

//Conversationsから自分のuidとmemberIdが一致するドキュメントIDを取得する
export const getConversation = async (clientUid?:string) => {
    console.log('getConversation',clientUid)
    const path = firstCollection5;
    if(clientUid){
        const terms = {
            memberId:clientUid,
        }
        const getIds = await getFirestoreData(path,terms);
        const result = getIds?.find((item:Conversations)=> item.memberId === clientUid)?.id;
        return result;

    } else {
        const terms = {
            memberId:user?.uid,
        }
        const getIds = await getFirestoreData(path,terms);
        const result = getIds?.find((item:Conversations)=> item.memberId === user?.uid)?.id;
        return result;
    }
}

// Conversationsから全てのドキュメントを取得する
export const getAllConversations = async () => {
    const path = firstCollection5;
    const result = await getFirestoreData(path);
    // user.uidを除外
    const filterResult = result?.filter((item:Conversations) => item.memberId !== user?.uid);
    console.log("getAllConversations",filterResult)
    return filterResult;
}

export const getConversationId = async () => {
    const path = firstCollection5;
    const terms = {
        memberId:user?.uid,
    }
    const result = await getFirestoreData(path,terms);
    return result;
}

// 全てのチャットメッセージを取得する

export const getAllChatMessages = async () => {
    const path = firstCollection6;
    const result = await getFirestoreData(path);
    return result;
}


//チャットのメッセージを取得する
export const getChatMessage = async (conversationId:string,setMessages:any) => {
    const path = firstCollection6;
    const result = await getFirestoreDataListener(path,conversationId,setMessages);
    return result;
}


// チャットのメッセージを送信する
export const sendChatMessage = async (conversationId:string,text:string) => {
    const path = firstCollection6;
    let click_action = "";
    const useAdminID = adminUids.find((adminId) => adminId.uid === user?.uid);
    console.log('useAdminID', useAdminID)
    if(!useAdminID){
        click_action = "./adminSettings/adminChat";
    } else {
        click_action = "./chat";
    }
    const sendChatMessage = {
        conversationId,
        text,
        senderId:user?.uid,
        createdAt:getTimestamp(),
        read:false,
        click_action,
    }
    const result = await addFirestoreData(path, sendChatMessage);
    return result;
}

// チャットのメッセージを編集する
export const editChatMessage = async (documentId:string, text:string) => {
    const path = firstCollection6;
    const result = await updateFirestoreData(path, documentId, { text:text });
    return result;
}

// チャットのメッセージを削除する
export const deleteChatMessage = async (documentId:string) => {
    const path = firstCollection6;
    const result = await deleteFirestoreData(path, documentId);
    return result;
}

// チャットを既読にする
export const readChatMessage = async (unreadMessages:ChatMessages[]) => {
    const path = firstCollection6;
    unreadMessages.forEach(async (item:ChatMessages) => {
        if(item.id){
            await updateFirestoreData(path, item.id, { read:true });
        }
        
    })
}

// 未読チャットがあるかを調べる
export const checkUnreadChatMessage = async () => {
    const conversationId = await getConversation();
    const path = firstCollection6;
    const unreadMessages = await getFirestoreData(path);
    const result = unreadMessages?.filter((item:ChatMessages) => {
        if(item.conversationId === conversationId && item.read === false && user?.uid !== item.senderId){
            console.log('checkUnreadChatMessage',item)
            return item;
        }
        }); 
    return result;
}


// チャットページを開いた先に自動的に最下部へ移動する
import { onMounted, watch, nextTick, ref,Ref } from 'vue';

export function useAutoScroll(contentRef: Ref<HTMLElement | null>) {
  const scrollToBottom = ref<(() => void) | null>(null);

  watch(contentRef, (newContentRef, oldContentRef) => {
    if (newContentRef !== null) {
      scrollToBottom.value = () => {
        nextTick(() => {
          newContentRef.scrollTop = newContentRef.scrollHeight;  // scrollTop を使ってスクロールを最下部へ移動
        });
      }
    }
  }, { immediate: true });

  return {
    scrollToBottom: scrollToBottom.value
  }
}

