import { app } from "./useFirebase";
import { getFunctions,httpsCallable,connectFunctionsEmulator } from 'firebase/functions';
// import { getFunctions,httpsCallable } from 'firebase/functions';
import { Member,pushMessages,UserData } from './useEnum';

const region = 'asia-northeast1'; //リージョン指定
const functions = getFunctions(app, region);
if(location.hostname === "localhost"){
    connectFunctionsEmulator(functions,"localhost",5001);
}

export async function issueMemberBackFunction(addMemberInfomation:Member){
    const issueMember = httpsCallable(functions,'issueMember');
    const uid = await issueMember(addMemberInfomation);
    return uid;
}

export async function addAdminRole(uid:string){
    const addAdminClaim = httpsCallable(functions,'addAdminClaim');
    await addAdminClaim(uid);
}

// トピックを登録するaddTokenToTopicを呼び出す。引数にはトピック名とトークンを渡す
export async function addTokenToTopic(topic:string,token:string){
    const addTokenToTopic = httpsCallable(functions,'addTokenToTopic');
    return addTokenToTopic({topic,token});
}

//トピックを解除する。引数にはトピック名とトークン
export async function subTokenToTopic(topic:string,token:string){
    const subTokenToTopic = httpsCallable(functions,'subTokenToTopic');
    return subTokenToTopic({topic,token});
}

// トピックに対して通知を送るsendTopicNotificationを呼び出す
export async function sendTopicNotification(notificationData:pushMessages){
    const sendTopicNotification = httpsCallable(functions,'sendTopicNotification');
    // Here, ensure that the Promise returned by the callable function is returned to the caller.
    return sendTopicNotification({notificationData});
}

export async function sendNotification(notificationData:pushMessages){
    const sendNotification = httpsCallable(functions,'sendNotification');
    // Here, ensure that the Promise returned by the callable function is returned to the caller.
    return sendNotification({notificationData});
}

export async function createUser(userData:UserData){
    const createUser = httpsCallable(functions,'createUser');
    const uid = await createUser(userData);
    return uid;
}