import { getFirestoreData,addFirestoreDataTransaction, addFirestoreData,updateFirestoreData } from "./helper/useFirestore"
import { StampCards, Stamp,Card,IssueTransaction,Terms,USE_RULES,USE_EFFECTION,RULE_EFFECTION } from "./helper/useEnum"
import { firstCollection1,firstCollection7,secondCollection5,secondCollection6 } from './helper/useFirestoreCollectionNames'
import { getAuth,onAuthStateChanged,setPersistence,browserLocalPersistence } from 'firebase/auth'
import { getUserStampcard,userCardDetail,user,stampCardDetail,allStampCards } from "./useStore"


// export const auth = getAuth();
// setPersistence(auth, browserLocalPersistence);
// console.log('setPersistence',browserLocalPersistence)
// let user = auth.currentUser;


// StampCardsコレクションのすべてのデータを取得する。idを引数に指定することで、1つだけ取得も出来る
export const getStampCardDetail = async (stampId?:string):Promise<StampCards[]> => {
    let stampCard:Promise<StampCards[]>;
    if(stampId){
        const path = `${firstCollection7}/${stampId}`;
        stampCard = await getFirestoreData(path);
    } else {
        const path = `${firstCollection7}`;
        stampCard = await getFirestoreData(path);
    }
    return stampCard;
}

// allStampCardsからdefault=trueのidを取得する
export const getDefaultStampCardId = () => {
    console.log('getDefaultStampCardId',allStampCards.value)
    const defaultStampCard = allStampCards.value.find((stampCard) => {
        return stampCard.default === true;
    });
    if(defaultStampCard === undefined || defaultStampCard.id === undefined)return '';
    return defaultStampCard.id;
}

// useStore内のstampCardDetailから、stampIdを引数に指定してStampCardsコレクションの特定のデータを取得する。
// ただしこちらは取得済みの一覧から特定idを探すので、通信及び非同期処理をしていない
export const selectStampCardDetail = (stampId:string) => {
    const selectStampCard = stampCardDetail.value.find((stampCard) => {
        return stampCard.id === stampId;
    });
    return selectStampCard
}

export const selectUserStampCard = (stampId:string) => {
    const selectUserStampCard = userCardDetail.value.find((stampCard) => {
        return stampCard.stampId === stampId;
    });
    return selectUserStampCard
}

export const setNewStampCard = async (newStampCard:StampCards) => {
    const stampPath = `${firstCollection7}`;
    console.log('setNewStampCard',newStampCard)
    const stamp:StampCards = {
        maxStampCounts: newStampCard.maxStampCounts,
        name: newStampCard.name,
        cardSheet: newStampCard.cardSheet,
        stampImage: newStampCard.stampImage,
        useExpiry: newStampCard.useExpiry,
        expiry: newStampCard.expiry,
        getLimit: newStampCard.getLimit,
        useRule: newStampCard.useRule,
        effection: newStampCard.effection,
        active:true,
        default:newStampCard.default,
    };
    console.log('setNewStampCard',stamp)
    const result = await addFirestoreData(stampPath,stamp);
    return result;
}

// default:trueが指定されているスタンプカードを所有していない場合に、新規ユーザーにスタンプカードを付与する
export const setNewStampCardtoUser = async () => {
    console.log('setNewStampCardtoUser')
    const stampId = allStampCards.value.find((stamp) => stamp.default === true)?.id;
    if(!stampId) return console.log("スタンプカードがありません");
    await setNewUserStampCard(stampId,0);
}

export const setNewUserStampCard = async (stampId:string, stampCounts:number):Promise<boolean> => {
    console.log('setNewUserStampCard',user)
    const stampCardDetail = allStampCards.value.find((stampCard) => {
        return stampCard.id === stampId;
    });
    if(stampCardDetail === undefined) return false;

    const useEffection = stampCardDetail.effection.map((effection:RULE_EFFECTION) => {
        const setEffection = {
            isUse:false,
            onPress:effection.onPress,
            discount:effection.discount,
        }
        return setEffection;
    })
    const stampPath = `${firstCollection1}/${user.value?.uid}/${secondCollection5}`;
    const cardPath = `${firstCollection1}/${user.value?.uid}/${secondCollection6}`;
    const stamp:Stamp = {
        createdAt: new Date(),
        stamp: stampCounts,
        stampId: stampId,
        whereGet: 'new',
    };
    const card:Card = {
        stampId:stampId,
        stamp:stampCounts,
        effection:useEffection,
        close:false,
    }
    const transactionData:IssueTransaction = [
        {
            path: stampPath,
            data: stamp,
        },
        {
            path: cardPath,
            data: card,
        }
    ]

    const result = await addFirestoreDataTransaction(...transactionData);
    return result;
}

export const updateStampCardsData = async (editStampCard:StampCards):Promise<boolean> => {
    const stampPath = `${firstCollection7}`;
    if(editStampCard.id === undefined) return false;
    const result = await updateFirestoreData(stampPath,editStampCard.id,editStampCard);
    return result;
}



export const getUserStampCards = async ():Promise<Card[]> => {
    const path = `${firstCollection1}/${user.value?.uid}/${secondCollection6}`;
    const stampCards = await getFirestoreData(path);
    // stampCardsからcloseがfalseのものだけを抽出する
    console.log('getUserStampCards stampCards',stampCards)
    const activeStampCards = stampCards?.filter((stampCard:Card) => {
        console.log('stampCard filter',stampCard.close)
        return stampCard.close === false;
    })
    console.log('activeStampCards',activeStampCards)
    // if(activeStampCards === undefined){
    //     return [];
    // }
    return activeStampCards;
}

export const getStampHistory = async (terms:any) => {
    // firstCollection1の中のsecondCollection5の中を、引数がない場合は全て取得する。引数がある場合はその条件に応じる
    const path = `${firstCollection1}/${user.value?.uid}/${secondCollection5}`;
    const term:Terms = {};
    if(terms === "today" && terms.whereGet){
        const startDate = new Date();
        const endDate = new Date();
        startDate.setHours(0,0,0,0);
        endDate.setHours(23,59,59,999);
        term.startDate = startDate;
        term.endDate = endDate;
        term.whereGet = terms.whereGet;
    } else if(terms === "today"){
        // termが日付型の場合、startDateとendDateを生成する。その日付型の当日の0時から23時59分までの間のデータを取得する。
        const startDate = new Date();
        const endDate = new Date();
        startDate.setHours(0,0,0,0);
        endDate.setHours(23,59,59,999);
        term.startDate = startDate;
        term.endDate = endDate;
    } else if(terms.whereGet){
        term.whereGet = terms.whereGet;
    }
    

    const stampHistory = await getFirestoreData(path,term);
    return stampHistory
}

export const showCanUseStampCard = (stampId:string):USE_EFFECTION[] | undefined => {
    console.log('showCanUseStampCard',stampId)
    const stampcardDetail:StampCards | undefined = selectStampCardDetail(stampId);
    const userStampCard:Card | undefined = selectUserStampCard(stampId);
    console.log('showCanUseStampCard',stampId,stampcardDetail,userStampCard)
    
    if(stampcardDetail === undefined || userStampCard === undefined || userStampCard.effection === undefined){
        throw new Error('stampcardDetailかuserStampCardかuserStampCard.effectionがundefinedです')
    }

    if(stampcardDetail.useRule === USE_RULES.PARTIAL){
        // stamCardDetail.effectionの値がfalseのものを抽出する
        const effection = userStampCard.effection.filter((effection:USE_EFFECTION) => {
            return effection.isUse === false;
        })
        return effection;
    } else if (stampcardDetail.useRule === USE_RULES.SINGLE) {
        const nowStampCount = userStampCard.stamp;
        const effections = userStampCard.effection;
        let foundEffection: USE_EFFECTION | undefined;
      
        for (let i = 0; i < effections.length - 1; i++) {
          if (effections[i].onPress <= nowStampCount && nowStampCount < effections[i + 1].onPress) {
            foundEffection = effections[i];
            break;
          }
        }
      
        console.log(foundEffection); // This will log the found effection or undefined if not found
        return foundEffection ? [foundEffection] : []; // If foundEffection is not undefined, return it within an array. Otherwise, return an empty array    
    } else if(stampcardDetail.useRule === USE_RULES.FULL){
        // userStampCard.effectionの最後の要素だけを返す。ただしarrayで
        const effection = userStampCard.effection[userStampCard.effection.length - 1];
        return [effection];
    }

}

export const useStampCard = async (stampId:string,onPress:number) => {
    // userCardDetailからstampIdが一致するものを取得する
    const selectCard = selectUserStampCard(stampId);
    console.log("useStampCard selectCard",selectCard)
    if(selectCard === undefined){return}    
    selectCard.effection?.find((effection:USE_EFFECTION) => {
        if(effection.onPress === onPress){
            effection.isUse = true;
        }
    }
    )
    const path = `${firstCollection1}/${user.value?.uid}/${secondCollection6}`;
    const data = {
        effection:selectCard.effection,
    }
    console.log('useStampCard data',data)
    if(selectCard.id === undefined || data ){return}
    const result = await updateFirestoreData(path,selectCard.id,data);
    console.log('useStampCard result',result)
}

export const setStamp = async (stampId:string, stampCounts:number, uid:string,where:string,):Promise<boolean> => {
    // const userStampCard = await getUserStampCards();
    const selectCardonStamp = userCardDetail.value.find((card:Card) => {
        card.stampId === stampId;
    })
    if(selectCardonStamp === undefined){
        throw new Error('スタンプカードが見つかりませんでした。');
    }

    const stampPath = `${firstCollection1}/${uid}/${secondCollection5}`;
    const cardPath = `${firstCollection1}/${uid}/${secondCollection6}`;
    const stamp:Stamp = {
        createdAt: new Date(),
        stamp: stampCounts,
        stampId: stampId,
        whereGet: where,
    };
    const totalStampCount = selectCardonStamp?.stamp + stampCounts;
    const card = {
        stamp:totalStampCount,
    }
    const transactionData:IssueTransaction = [
        {
            path: stampPath,
            data: stamp,
        },
        {
            path: cardPath,
            data: card,
        }
    ]

    const result = await addFirestoreDataTransaction(...transactionData);
    return result;
}

export const validateStampCard = (stampCard:StampCards) => {
    if (!stampCard.name) return "スタンプカードの名前が必要です";
    if (!stampCard.maxStampCounts) return "最大スタンプ数が必要です";
    if (!stampCard.cardSheet) return "スタンプカードの画像が必要です";
    if (!stampCard.stampImage) return "スタンプの印影が必要です";
    if (stampCard.useExpiry && !stampCard.expiry) return "有効期限が必要です";
    if (!stampCard.getLimit) return "1日のスタンプ取得の上限数が必要です";
    if (!stampCard.useRule) return "スタンプカードの使用ルールが必要です";
    if (!stampCard.effection.length) return "割引ルールが必要です";
    if (stampCard.default === undefined) return "デフォルト設定が必要です";
    if (stampCard.active === undefined) return "アクティブ設定が必要です";

    // Checking if maxStampCounts exists as onPress in effection
    const hasMaxStampCounts = stampCard.effection.some(rule => rule.onPress === stampCard.maxStampCounts);
    if (!hasMaxStampCounts) return "最大スタンプ数が割引ルールに設定されていません";

    // Discount rules validation
    for (const rule of stampCard.effection) {
        if (!rule.onPress) return "割引ルールにスタンプ数が設定されていません";
        if (!rule.discount) return "割引ルールに割引金額が設定されていません";
    }

    return true;
}



