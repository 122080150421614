import {ref ,Ref,reactive, computed} from 'vue'
import {getMembers,getAllMembers,getPushMessage,checkUnreadNotification,readPushMessage} from '@/composables/useMembers'
import { ChatMessages,TypeNotification,Card,StampCards,USE_EFFECTION,USE_RULES,Member } from '@/composables/helper/useEnum'
import { getConversationId } from '@/composables/useMembers'
import { getUserStampCards,getStampCardDetail,showCanUseStampCard,setNewUserStampCard } from '@/composables/useStamps'
import { getAuth,onAuthStateChanged,setPersistence,browserLocalPersistence,User  } from 'firebase/auth'

// import { getDateString } from '@/composables/helper/useDateProcessing'

export const memberDetail: Ref<Member> = ref({
    name: '',
    email: '',
    id:'',
});
export const allMemberDetail: Ref<Member[]> = ref([]);
export const selectEditMember: Ref<Member | undefined> = ref({
    name: '',
    email: '',
    id:'',
});
export const notifications: Ref<TypeNotification[]> = ref([])
export const conversationId = ref();
export const userCardDetail: Ref<Card[]> = ref([]);
export const stampCardDetail : Ref<StampCards[]> = ref([]);
export const useEffection : Ref<USE_EFFECTION[]> = ref([]);
export const allStampCards : Ref<StampCards[]> = ref([]);
export const activeStampCards : Ref<StampCards[]> = ref([]);
export const selectStampCardId : Ref<string | null> = ref(null);
export const editStampCard : Ref<StampCards> = ref({
    name: '',
    maxStampCounts: 1,
    cardSheet: '',
    stampImage: '',
    useExpiry: false,
    expiry: new Date(),
    getLimit: 1,
    useRule: USE_RULES.FULL,
    effection: [
        { onPress: 0,discount:0, }
    ],
    default: false,
    active: true,
});
export const auth = getAuth();
setPersistence(auth, browserLocalPersistence);
console.log('useStore setPersistence',browserLocalPersistence)
export const user: Ref<User | null> = ref(null);
onAuthStateChanged(auth, (currentUser) => {

  user.value = currentUser;
});
export const getMemberData = async () => {
    memberDetail.value = await getMembers();
    memberDetail.value.id = user.value?.uid;
    console.log('useStore member',memberDetail.value)
}

export const getAllMemberData = async () => {
    allMemberDetail.value = await getAllMembers();
    console.log('useStore member',memberDetail.value)
}

export const getSelectEditMember = async (id?:string) => {
    if(!id){
        // id = user.value?.uid;
        selectEditMember.value = memberDetail.value;
        return;
    }
    console.log('getSelectEditMember',id,allMemberDetail.value)
    selectEditMember.value = await allMemberDetail.value.find((member) => {
        return member.id === id;
    }
    )
    console.log('selectEditMember',selectEditMember.value)
}

export const getNotifications = async () => {
    console.log('getNotifications')
  const pushMessages = await getPushMessage();
  const unReadList = await checkUnreadNotification();
  console.log('unReadList',unReadList)
  if(!unReadList && Array.isArray(pushMessages)){
    console.log('既読なし')
    pushMessages.forEach((pushMessage) => {
        pushMessage.read = false;
    })
    notifications.value.push(...pushMessages);
} else if(unReadList && Array.isArray(pushMessages)) {
    console.log('既読あり')
    // pushMessages.idのうち、unReadList.idと一致するものがあれば、pushMessageの該当するidにread:trueを入れる
    pushMessages.forEach((pushMessage) => {
        pushMessage.read = unReadList.some((unRead:any) => pushMessage.id === unRead.id);
    });
    console.log('pushMessages',pushMessages)
      notifications.value.push(...pushMessages);
      console.log('notifications', notifications.value)
  }
}

export const getNotificationDetail = async (id:string) => {
    console.log("getNotificationDetail")
    console.log("store notifications", notifications.value)
    if(notifications.value.length === 0){
        await getNotifications();
    }
    return notifications.value.find((notification) => {
        if(notification.id === undefined){
            return notification.id === undefined
        }else {
           console.log("store notification", notification.id,id)
            return notification.id === id; 
        }
        
    }
    );
}

export const readNotification = async (id:string) => {
    const notification = notifications.value.find((notification) => {
        return notification.id === id;
    }
    );
    if(!notification){
        throw new Error('エラーが出ました');
    }
    if(!notification.read){
        await readPushMessage(id);
        notification.read = true;
    }
}

export const getConversation = async () => {
    const conversation = await getConversationId();
    conversationId.value = conversation?.id
}

export const getUserStampcard = async() => {
    userCardDetail.value = await getUserStampCards();
    console.log('userCardDetail',userCardDetail.value)
    // userCardDetail.stampIdとallStampCardsのidと一致するものを探して、userCardDetailにnameを追加する
    userCardDetail.value.forEach((userCard:Card) => {
        const stampCard = allStampCards.value.find((stampCard:StampCards) => {
            return userCard.stampId === stampCard.id;
        });
        if(stampCard){
            userCard.name = stampCard.name;
        }
    }
    );
}

export const getstampcardDetail = async(id?:string) => {
    stampCardDetail.value = await getStampCardDetail(id ? id : '');
    console.log('stampCardDetail',stampCardDetail.value)
}

export const getUseEffection = async(id:string) => {
    const effections:USE_EFFECTION[] | undefined = await showCanUseStampCard(id);
    if(effections === undefined){
        throw new Error('エラーが出ました');
    }
    console.log('useEffection',useEffection.value)
    useEffection.value = effections;
}

export const getAllStampCardsData = async(setNewStampCard?:boolean) => {
    allStampCards.value = await getStampCardDetail();
    console.log('allStampCards',allStampCards.value)
    if(allStampCards.value.length === 0){
        return;
    }
    await checkActiveStampCard();
    await getUserStampcard();
    if(!setNewStampCard) return;
    // allStampCards.valueの配列のうち、defaultがtrueのものだけを抽出。なければreturnを返す
    const addStampCard = allStampCards.value.find((allStampCard:StampCards) => {
        return allStampCard.default === true;
    }
    );
    if(addStampCard === undefined ||addStampCard.id === undefined) return;
    await setNewUserStampCard(addStampCard.id,0);
}

const checkActiveStampCard = () => {
    const activeStampId = allStampCards.value.filter((stampCard:StampCards) => {
        return stampCard.active === true;
    });
    activeStampCards.value = activeStampId;
}

// selectStampCardIdにstampIdを入れる
export const setSelectStampCardId = (id:string) => {
    selectStampCardId.value = id;
}

export const getSelectStampCardId = () => {
    return selectStampCardId.value;
}
