import { B } from "@fullcalendar/core/internal-common";

export type UserData = {
  email:string;
  password:string;
  name:string;
}
export type Terms = { //firestoreの取得データの条件を指定するオブジェクト
    limitNum?: number;
    orderByKey?: string;
    startDate?: Date;
    endDate?: Date;
    uid?: string;
    memberId?: string;
    order?: 'asc' | 'desc';
    topic?: string;
    whereGet?: string;
}

export type Point = {
    point: number,
}

export type Coupon = { //クーポンのフォーマット
    name?: string,
    description?: string,
    discount?: number,
    useLimitDate?: string | Date,
    noDuplicate?: boolean,
    id?: string,
}

export type MemberCoupon = { //会員のクーポンのフォーマット
    use: boolean,
}

export type Member = { //会員のフォーマット
    name?: string,
    email?: string,
    password?: string,
    point?: number,
    coupons?: Coupon[]
    events?: string[],
    uid?: string,
    id?:string,
    pushToken?: string,
    chatPermission?: boolean,
    infomationPermission?: boolean,
}

export type Event = { //イベントのフォーマット
    name?: string,
    description?: string,
    startDate?: Date,
    endDate?: Date,
    reservationEndDate?: Date,
    limitNum?: number,
    members?: string[]
}

export type TransactionItem = {
    path: string;
    id?: string;
    data: Member | Coupon | Event | Point | pushMessages | Stamp | Card;
  };
  
  export type IssueTransaction = TransactionItem[];
  
  export type pushMessageArray = pushMessages[]

  export type pushMessages = {
    title: string;
    body: string;
    createdAt: string;
    read?: boolean;
    topic: string;
    conversationId?: string;
    click_action?: string;
  }

  export type Conversation = {
    // conversationId: string;
    member: string;
    admin: string;
  }

  export type ChatMessages = {
    conversationId?: string;
    senderId?: string;
    timestamp?: Date;
    text?: string;
    read?: boolean;
    click_action?: string;
    id?: string;
  }

  export type Conversations = {
    adminId:string;
    adminId2: string;
    memberId: string;
    clientName: string;
  }

  export type TypeNotification = {
    id: string;
    title: string;
    text: string;
    read: boolean;
    createdAt: string;
    type?: string;
  }

  export type StampCards = {
    id?: string;
    default?:boolean;
    maxStampCounts:number;
    name:string;
    cardSheet:string;
    stampImage:string;
    useExpiry:boolean;
    expiry?:Date;
    getLimit:number;
    useRule:USE_RULES;
    effection:RULE_EFFECTION[];
    active:boolean;
  }

  export enum USE_RULES {
    PARTIAL= 'partialUseAllowed',
    SINGLE=  'singleUseOnly',
    FULL=  'fullUseRequired',
  }

  export type RULE_EFFECTION = {
    onPress:number;
    discount:number;
  }

  export type Stamp = {
    createdAt:Date;
    stamp:number;
    stampId:string;
    whereGet:string | number;
  }

  export type Card = {
    stampId?:string;
    stamp:number;
    effection?:USE_EFFECTION[];
    close?:boolean;
    name?:string;
    id?:string;
  }

  export type USE_EFFECTION = {
    isUse:boolean;
    onPress:number;
    discount:number;
  }