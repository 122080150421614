//useFirebase.tsからappをimportする
import { app } from './useFirebase';

// Import the functions you need from the SDKs you need
import { onSnapshot,connectFirestoreEmulator,getFirestore, collection, getDoc, getDocs, addDoc, setDoc, doc, deleteDoc, updateDoc, query, limit, orderBy, Query, where, runTransaction, deleteField,QueryConstraint,Timestamp,serverTimestamp} from "firebase/firestore";
import { Terms,IssueTransaction,TransactionItem,ChatMessages,Coupon,Member} from './useEnum';

// Initialize Firebase
const db = getFirestore(app);
if (location.hostname === "localhost") {
    console.log("テスト環境")
    connectFirestoreEmulator(db, 'localhost', 8081);
  }

// firestoreからデータを取得するメイン関数。第一引数にパス、第二引数をtermsという名前にして、取得したいデータ数、ソート順を指定するオブジェクトにする。
// まず第一引数のパスを/で区切り、区切った数に応じてcollectionかdocかを判定して、それぞれの関数を呼び出す。
// その後、第二引数の有無によって、それぞれの関数を呼び出す。
export const getFirestoreData = async (path: string, terms?:Terms) => {
    const pathArray = path.split('/');
    if (pathArray.length % 2 !== 0) {
        console.log('collection')
        return await getCollectionData(path, terms);
    } else {
        console.log('doc')
        return await getDocData(path);
    }
}

//limitによる取得数の制限、orderByによるソート条件、またはその両方を指定して、データを取得する関数。
export const getCollectionData = async (path: string, terms?: Terms):Promise<any> => {
    console.log('terms', terms)
    const collectionRef = collection(db, path);
    const constraints: QueryConstraint[] = [];

    if (terms) {
        if (terms.uid) {
            console.log('uid')
            constraints.push(where('uid', '==', terms.uid));
        }
        if (terms.startDate && terms.endDate && terms.orderByKey) {
            console.log('Date')
            constraints.push(where(terms.orderByKey, '>=', terms.startDate));
            constraints.push(where(terms.orderByKey, '<=', terms.endDate));
        }
        if (terms.orderByKey) {
            console.log('orderBy')
            const order = terms.order ? terms.order : 'desc';
            constraints.push(orderBy(terms.orderByKey, order));
        }
        if (terms.limitNum) {
            console.log('limit')
            constraints.push(limit(terms.limitNum));
        }
        if (terms.topic) {
            console.log('topic')
            constraints.push(where('topic', '==', terms.topic));
        }
        if (terms.whereGet) {
            console.log('whereGet')
            constraints.push(where('whereGet', '==', terms.whereGet));
        }
    }

    const queryRef = query(collectionRef, ...constraints);


    //queryRefからドキュメントIDを取得し、collectionSnapに含める
    const collectionSnap = await getDocs(queryRef);
    const collectionData = collectionSnap.docs.map(doc => {
        return { ...doc.data(), id: doc.id }
    }
    );
    return collectionData;
}



//docのデータを取得する関数。
export const getDocData = async (path: string) => {
    const docRef = doc(db, path);
    const docSnap = await getDoc(docRef);
    console.log('docSnap', docSnap.exists(),docSnap.data())
    if (docSnap.exists()) {
        console.log('docSnap.data()', docSnap.data())
        return docSnap.data();
    }
}

//documentIDを取得する関数
export const getDocId = async (path: string) => {
    const docRef = doc(db, path);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.id;
    }
}

//firestoreにデータを追加する関数。第一引数にパス、第二引数に追加するデータを指定する。
//第一引数のパスを/で分割して、奇数ならそのまま、偶数なら最後の要素をdocumentIDとしてデータを追加する
export const addFirestoreData = async (path: string, data: any): Promise<string> => {
    const pathArray = path.split('/');
    let docId = '';
    if (pathArray.length % 2 !== 0) {
        const docRef = await addDoc(collection(db, path), data);
        docId = docRef.id;
    } else {
        await setDoc(doc(db, path), data, { merge: true });
        const pathComponents = path.split('/');
        docId = pathComponents[pathComponents.length - 1];
    }
    return docId;
}


//firestoreのデータを削除する関数。第一引数にパス、第二引数に削除するデータのidを指定する。
export const deleteFirestoreData = async (path: string, id:string) => {
    const docRef = doc(db, path, id);
    await deleteDoc(docRef);
}

export const deleteFirestoreDataField = async (path: string, id:string, fieldName:string) => {
    const docRef = doc(db, path, id);
    console.log('deleteFirestoreDataField',path,id,fieldName)
    await updateDoc(docRef,{
        [fieldName]:deleteField()
    });
}

//firestoreのデータを更新する関数。第一引数にパス、第二引数に更新するデータのid、第三引数に更新するデータを指定する。
export const updateFirestoreData = async (path: string, id: string, data: ChatMessages | Coupon | Member) => {
    const docRef = doc(db, path, id);
    await updateDoc(docRef, data);
    return true;
}

//firestoreの2つの値を同時に更新するトランザクション処理
export const updateFirestoreDataTransaction = async (path1: string, id1: string, data1: any, path2: string, id2: string, data2: any) => {
    try{
        const docRef1 = doc(db, path1, id2);
        const docRef2 = doc(db, path2, id2);
        await runTransaction(db, async (transaction) => {
            transaction.update(docRef1, data1);
            transaction.update(docRef2, data2);
        });
        return true;
    }catch(e){
        console.log(e);
        return false;
    }
    
}

//firestoreに同時に複数の値を書き込みするトランザクション処理
//引数は全て配列で、中のオブジェクトにパス、指定があればドキュメントID、書き込むデータとする
//配列の数の分だけ処理をする
export const addFirestoreDataTransaction = async (...args: IssueTransaction) => {
    try {
        await runTransaction(db, async (transaction) => {
            args.forEach((arg:TransactionItem) => {
                let docRef;
                if (arg.id) {
                    docRef = doc(db, arg.path, arg.id);
                } else {
                    docRef = doc(collection(db, arg.path)); // Auto generate document id
                }
                transaction.set(docRef, arg.data);
            })
        });
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

// リスナーモードでデータを取得する関数
// queryでconversationIdが一致するデータを、タイムスタンプの日付が新しい順に取得する
// 新規メッセージの取得、メッセージの編集、メッセージの削除に使用する
export const getFirestoreDataListener = (path: string, conversationId: string, callback: (data: any) => void) => {
    const collectionRef = collection(db, path);
    console.log("path",path)
    const queryRef = query(collectionRef, where("conversationId", "==", conversationId), orderBy("createdAt", "asc"));
    const unsubscribe = onSnapshot(queryRef, (querySnapshot) => {
        const data = querySnapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
        }
        );
        console.log("getFirestoreDataListener",data);
        callback(data);
    });
    return unsubscribe;
    
}

export const getTimestamp = () => {
    return Timestamp.now();
}