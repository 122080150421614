import { getMessaging, getToken, onMessage,deleteToken } from "firebase/messaging";
import { app } from "@/composables/helper/useFirebase"
import { getPushMessage } from "@/composables/useMembers"
import { TypeNotification } from "@/composables/helper/useEnum";
import { ref } from "vue";
import { useRouter } from "vue-router"
const router = useRouter()

export const messaging = getMessaging(app);
console.log('usePushNotification.ts',messaging)
const vapidKey = "BC-7ZT5oofMRKrKoNTrwGUboXGKz7hOBDFm7-1ctm_P4AzomF1tCDYSzHTIIx42qHeDK3VjVDmYhR4WrpGYiWGw"

// ブラウザの通知許可を得る
export const permissionCheck = () => {
  return Notification.requestPermission().then((permission) => {
    if(permission === "granted"){
      console.log("Notification permission granted.");
      return true
  } else {
    console.log("Unable to get permission to notify.");
    return false
  }
})
}

//プッシュ通知の許可を求める
export const requestPermission = (): Promise<string | boolean> => {
  console.log('requestPermission', Notification)
  return Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      return getToken(messaging, { vapidKey })
        .then((currentToken) => {
          if (currentToken) {
            return currentToken;
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
            return false; // Return false if no currentToken is available.
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          return false;
        });
    } else {
      console.log("Unable to get permission to notify.");
      return false;
    }
  });
};

// FCMのトピックの許可を取る
// export const requestTopicPermission = (topic:string) => {
//   getToken(messaging).then(token => {
//     return messaging.subscribeToTopic(token, topic)
//   })



// Callback fired if Instance ID token is updated.
export const refreshPushToken = () => {
  return getToken(messaging, { vapidKey })
    .then((refreshedToken) => {
      console.log('Token refreshed.', refreshedToken);
      return refreshedToken;
    })
    .catch((err) => {
      console.log('Unable to retrieve refreshed token ', err);
    });
};

//トークンを削除する
export const deletePushToken = async () => {
  return await deleteToken(messaging)
    .then(() => {
      console.log('Token deleted.');
    })
    .catch((err) => {
      console.log('Unable to delete token. ', err);
    });
};

// 送信されたプッシュ通知を取得して、sessionstorageに保存する
export const getNotificationsAndSetSession = async () => {
  let notifications:TypeNotification[] = [];
  const pushMessages = await getPushMessage();
  console.log("notifications", notifications)
  if(Array.isArray(pushMessages)){
  notifications = [...notifications, ...pushMessages];
  }
  // Object.assign(notifications, pushMessages);
  // sessionstorageへ書き込み
  sessionStorage.setItem('notifications', JSON.stringify(notifications));
  return notifications;
}

export const unreadNotificationSet = () => {
  let notifications:TypeNotification[] = [];
  notifications = JSON.parse(sessionStorage.getItem('notifications') || '[]');
  const unreads = notifications.filter((notification) => {
    return notification.read === false;
  });
  return unreads.length;
}


// Optional: Handle in-foreground messages
// export const sendPushNotification = onMessage(messaging, (payload) => {
//   console.log('Message received. ', payload);
// });

// フォアグラウンドでのプッシュ通知を受け取る
export const onForegroundMessage = () => {
  console.log('onForegroundMessage',messaging)
  const message = ref<any>(null)
  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    message.value = payload
    console.log('フォアグラウンド')
  }
  );
  return { message }
}

// 