import { DirectiveBinding } from 'vue'

export const longpressDirective = {
    beforeMount: (el: HTMLElement, binding: DirectiveBinding) => {
        let pressTimer: ReturnType<typeof setTimeout> | null = null
        let start: number;
        const cancel = () => {
            if (pressTimer !== null) {
                clearTimeout(pressTimer)
                pressTimer = null
            }
        }
        const handler = (e: Event) => {
            cancel();
            binding.value(e)
        }
        const touchstart = (e: Event) => {
            e.preventDefault(); // Prevents default context menu
            start = new Date().getTime()
            pressTimer = setTimeout(() => {
                handler(e)
            }, 1000) // you can set your time that is recognized as long press
        }
        el.addEventListener("touchstart", touchstart);
        el.addEventListener('contextmenu', (e) => e.preventDefault());
    },
}
